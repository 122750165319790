<template>
    <button class="p-2 flex items-center rounded-md" :class="[styles, sizeStyles]">
        <font-awesome v-if="icon && !loading" class="h-3.5 w-3.5 mr-1" :icon="icon" />
        <div v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" />
        <slot></slot>
        <font-awesome v-if="rightIcon" class="h-3.5 w-3.5 ml-1" :icon="rightIcon" />
    </button>
</template>

<script setup lang="ts">
    const { type, loading, icon, rightIcon, size } = defineProps<{
        type?: 'none' | 'primary' | 'secondary' | 'danger' | 'tertiary-secondary'
        loading?: boolean
        icon?: any
        rightIcon?: any
        size?: 'small' | 'normal'
    }>()

    const styles = computed(() => ({
        none: '',
        primary: 'bg-primary hover:bg-primary-dark disabled:bg-primary-soft text-white',
        secondary: 'border-gray-400 hover:bg-gray-200 text-black bg-white border',
        danger: 'bg-danger hover:bg-danger-dark disabled:bg-danger-disabled text-white',
        'tertiary-secondary': 'hover:bg-gray-100'
    }[type || 'primary']))

    const sizeStyles = computed(() => ({
        small: 'px-2 py-1 text-sm',
        normal: 'p-2 text-base'
    }[size || 'normal']))
</script>

<style scoped>
    @-webkit-keyframes spinner-border {
        to {
            transform: rotate(360deg) /* rtl:ignore */;
        }
    }

    @keyframes spinner-border {
        to {
            transform: rotate(360deg) /* rtl:ignore */;
        }
    }
    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: -0.125em;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: 0.75s linear infinite spinner-border;
        animation: 0.75s linear infinite spinner-border;
    }

    .spinner-border-sm {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em;
    }
</style>

